<template>
  <b-container fluid>
    <b-row>
      <b-col cols="auto" lg="5" class="marginLeft5">
        <b-form-group
          label="Charges file from PRIORITY report (GIYUL):"
          label-cols="auto"
          label-align-sm="left"
          label-size="sm"
        >
          <b-form-file
            v-model="file1"
            :state="Boolean(file1)"
            size="sm"
            @input="fileInput"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-button variant="outline-primary" size="sm" @click="updateBalance"
          >Update Balance</b-button
        >
      </b-col>
      <b-col cols="auto">
        <b-form-group
          label="Charge date:"
          label-cols="auto"
          label-align-sm="left"
          label-size="sm"
        >
          <b-form-datepicker
            placeholder="Charge date"
            v-model="chargeDate"
            size="sm"
            class="mb-2"
            locale="en"
            today-button
            close-button
            value-as-date
          ></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table
      :items="items"
      :fields="fields"
      :busy.sync="isBusy"
      show-empty
      striped
      hover
      class="my-1 text-left fontSizeSm"
      bordered
      small
      fixed
      responsive
      sticky-header="790px"
      :no-border-collapse="noCollapse"
      size="sm"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <!-- <template v-slot:cell(chargeDate)="row">
         <div class="text-truncate">{{ row.item.chargeDate }}</div>
          <b-form-datepicker v-model="row.item.chargeDate" />
        </template> -->
    </b-table>
  </b-container>
</template>

<script>
import { postDataAsync, postAsync, safeFileWithDate } from "../apiHelper";

export default {
  data() {
    return {
      file1: null,
      chargeDate: new Date(),
      items: [],
      fields: [
        {
          key: "count",
          sortable: true,
          thClass: "w10Column",
        },
        {
          key: "cephxID",
          label: "Cephx ID",
          sortable: true,
          thClass: "w10Column",
        },
        {
          key: "customerName",
          label: "Customer Name",
          sortable: true,
        },
        {
          key: "amount",
          label: "Amount",
          sortable: true,
          thClass: "w10Column",
        },
        {
          key: "subscriberID",
          label: "Subscriber ID",
          sortable: true,
          thClass: "w15Column",
        },
      ],
      isBusy: false,
      noCollapse: true,
      msgConfig: {
        // title: "Info",
        size: "sm",
        buttonSize: "sm",
        // okVariant: "success",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
        centered: true,
      },
    };
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  methods: {
    async fileInput(file) {
      this.isBusy = true;
      // console.log(file);
      this.items = [];
      let url = `${this.$hostname}/api/bsBalance/processChargesFileFromPriorityReport`;
      let formData = new FormData();
      formData.append("file", file);
      let responseData = await postDataAsync(url, formData);
      if (responseData) {
        this.items = responseData;

        this.items.forEach((element) => {
          if (element.amount <= 0) {
            element._rowVariant = "danger";
          }
        });
        if (!this.items || !this.items.length) {
          this.$bvModal.msgBoxOk(
            "There are no customers with subscriber id in this file.",
            this.msgConfig
          );
        } else {
          url = `${this.$hostname}/api/bsBalance/createCsvFileFromReportItems`;
          let response = await postAsync(url, this.items, "blob");
          if (response.status == 200 && response.data) {
            safeFileWithDate(response.data, "BS_Balance", "csv", true);
          }
        }
      }
      this.isBusy = false;
    },
    updateBalance() {
      var posItems = this.items.filter(x => x.amount > 0);
      if (posItems.length == 0) {
        this.$bvModal.msgBoxOk(
          "No subscribers to update balance.",
          this.msgConfig
        );
      } else {
        let text = `Please approve updating balance for ${posItems.length} subscribers from "${this.file1.name}"`;
        posItems.forEach((element) => {
          element.chargeDate = this.chargeDate;
        });
        this.$bvModal
          .msgBoxConfirm(text, {
            size: "sm",
            buttonSize: "sm",
            okTitle: "OK",
            cancelTitle: "Cancel",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then(async (value) => {
            if (value) {
              this.isBusy = true;
              let url = `${this.$hostname}/api/bsBalance/updateBalancesForReportItems`;
              let response = await postAsync(url, posItems);
              if (response.status == 200) {
                this.$bvModal.msgBoxOk(
                  "All subscriptions were updated successfully.",
                  this.msgConfig
                );
              } else {
                this.$bvModal.msgBoxOk(response.data, {
                  size: "sm",
                  buttonSize: "sm",
                  okVariant: "danger",
                  headerClass: "p-2 border-bottom-0",
                  footerClass: "p-2 border-top-0",
                  centered: true,
                });
              }
              this.isBusy = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

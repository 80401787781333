<template>
  <b-container fluid>
    <b-row>
      <b-col cols="auto" class="my-1">
        <b-button variant="outline-primary" size="sm" @click="showIgnoredDoctors">
          Show Ignored Doctors
          <b-spinner small v-if="isIgnoredBusy"></b-spinner>
        </b-button>
      </b-col>
      <b-col cols="auto" class="my-1 marginLeft5">

        <b-form-group label="Per page:" label-for="per-page-select" label-cols="auto" class="mb-0"
          label-class="lb-date b-0">
          <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="auto" class="my-1">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
          class="my-0" @input="handlePageClick($event)"></b-pagination>
      </b-col>

      <b-col cols="auto" class="my-2">
        <b-form-checkbox v-model="checkAll" switch @change="switchAll">
          Check All
        </b-form-checkbox>
      </b-col>

      <b-col cols="auto" class="my-2">
        <b-form-checkbox v-model="debug" switch> Debug </b-form-checkbox>
      </b-col>

      <b-col cols="auto" class="my-2">
        <b-form-checkbox v-model="addAnalysisID" switch>
          Add Analysis-ID
        </b-form-checkbox>
      </b-col>

      <b-col cols="auto" class="my-2">
        <b-form-checkbox v-model="updateCTDent" switch>
          Update CT-Dent
        </b-form-checkbox>
      </b-col>

      <b-col cols="auto" class="my-1">
        <b-form-group label="From:" label-cols="auto" label-class="lb-date">
          <b-form-datepicker placeholder="Choose a date" v-model="fromDate" size="sm" locale="en" today-button
            close-button value-as-date></b-form-datepicker>
        </b-form-group>
      </b-col>

      <b-col cols="auto" class="my-1">
        <b-button variant="outline-primary" size="sm" @click="continueFlow">Download</b-button>
      </b-col>

      <b-col cols="auto" class="my-1">
        <b-button variant="outline-primary" size="sm" @click="ignoreDoctors">Ignore Doctors
          <b-spinner small v-if="isIgnoringBusy"></b-spinner>
        </b-button>
      </b-col>
    </b-row>

    <b-table :items="items" :fields="fields" :busy.sync="isBusy" :per-page="perPage" :current-page="currentPage"
      show-empty striped hover class="my-1 text-left fontSizeSm" bordered small fixed responsive sticky-header="850px"
      :no-border-collapse="noCollapse" size="sm" @sort-changed="sortChanged">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>{{ loadText }}</strong>
        </div>
      </template>

      <!-- A custom formatted header cell for field 'sync' -->
      <template #head(sync)="data">
        <span>{{ data.label }}</span>
        <b-form-checkbox @change="checkSyncAll" v-model="syncAll" />
      </template>
      <!-- A custom formatted row cell for field 'sync' -->
      <template v-slot:cell(sync)="row">
        <b-form-checkbox @change="checkSync" v-model="row.item.sync" />
      </template>
      <!-- A custom formatted header cell for field 'ignore' -->
      <template #head(ignore)="data">
        <span>{{ data.label }}</span>
        <b-form-checkbox @change="checkIgnoreAll" v-model="ignoreAll" />
      </template>
      <!-- A custom formatted row cell for field 'ignore' -->
      <template v-slot:cell(ignore)="row">
        <b-form-checkbox @change="checkIgnore" v-model="row.item.ignore" />
      </template>

      <template #cell()="data">
        <div class="text-truncate">{{ data.value }}</div>
      </template>
    </b-table>

    <b-modal id="modal-ignored-doctors-1" ref="modal-ignored-doctors-1" title="Ignored Doctors With Orders"
      ok-title="Continue" size="xl" centered scrollable ok-only>
      <b-table :items="ignoredDoctors" :fields="ignoredDoctorFields" show-empty striped hover
        class="my-1 text-left fontSizeSm" bordered small fixed responsive sticky-header="500px"
        :no-border-collapse="noCollapse" size="sm">

        <template #cell(number)="row">
          <div class="text-truncate">{{ row.index + 1 }}</div>
        </template>

        <template #cell()="data">
          <div class="text-truncate">{{ data.value }}</div>
        </template>

      </b-table>
    </b-modal>
  </b-container>
</template>

<script>
import {
  getItemsAsync,
  postDataAsync,
  postAsync,
  safeFileWithDate,
} from "../apiHelper";
import {
  switchItems,
  getItemsValue,
  getSortedItems,
  getDateString
} from "../codeHelper";

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 15, 20, 50, 100, 999],
      isBusy: true,
      isIgnoredBusy: false,
      isIgnoringBusy: false,
      checkAll: false,
      debug: true,
      addAnalysisID: false,
      updateCTDent: true,
      fromDate: new Date(),
      fields: [
        {
          key: "sync",
          sortable: false,
          thClass: "syncTh",
          tdClass: "syncTd",
        },
        {
          key: "ignore",
          sortable: false,
          thClass: "syncTh",
          tdClass: "syncTd",
        },
        {
          key: "full_name",
          label: "Customer",
          sortable: true,
        },
        {
          key: "doctor_number",
          label: "Customer Number",
          sortable: true,
        },
        {
          key: "orders_count",
          label: "# Orders",
          sortable: true,
          thClass: "w10Column",
        },
        {
          key: "email",
          label: "E-mail",
          sortable: true,
        },
        {
          key: "city",
          sortable: true,
        },
        {
          key: "state",
          sortable: true,
          thClass: "w10Column",
        },
      ],
      items: [],
      ignoredDoctors: [],
      ignoredDoctorFields: [
        {
          key: "number",
          label: "#",
          thClass: "numberColumn",
        },
        {
          key: "doctor_number",
          label: "Customer Number",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "First Name",
          sortable: true,
        },
        {
          key: "last_name",
          label: "Last Name",
          sortable: true,
        },
        {
          key: "country",
          label: "Country",
          sortable: true,
        },
        {
          key: "orders_count",
          label: "# Orders",
          sortable: true,
        },
        {
          key: "first_order",
          label: "First Order in Month",
          sortable: true,
        },
        {
          key: "last_order",
          label: "Last Order in Month",
          sortable: true,
        },
      ],
      noCollapse: true,
      loadText: "Loading...",
      syncAll: false,
      ignoreAll: false,
      sortBy: null,
      sortDesc: false
    };
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  methods: {
    sortChanged(e) {
      this.sortBy = e.sortBy;
      console.log(e);
      this.sortDesc = e.sortDesc;
      this.handlePageClick();
    },
    handlePageClick() {
      let sorted = getSortedItems(this.items, this.sortBy, this.sortDesc);
      this.syncAll = getItemsValue(sorted, 'sync', this.currentPage, this.perPage);
      this.ignoreAll = getItemsValue(sorted, 'ignore', this.currentPage, this.perPage);
    },
    checkSyncAll(value) {
      if (this.items) {
        let sorted = getSortedItems(this.items, this.sortBy, this.sortDesc);
        switchItems(sorted, 'sync', this.currentPage, this.perPage, value);
      }
    },
    checkIgnoreAll(value) {
      if (this.items) {
        let sorted = getSortedItems(this.items, this.sortBy, this.sortDesc);
        switchItems(sorted, 'ignore', this.currentPage, this.perPage, value);
      }
    },
    checkSync() {
      this.syncAll = getItemsValue(this.items, 'sync', this.currentPage, this.perPage);
    },
    checkIgnore() {
      this.ignoreAll = getItemsValue(this.items, 'ignore', this.currentPage, this.perPage);
    },
    switchAll(value) {
      if (this.items) {
        this.items.forEach((item) => (item.sync = value));
        this.syncAll = value;
        //this.checkSync();
      }
    },
    async getCustomers() {
      if (this.items.length == 0) {
        // https://bootstrap-vue.org/docs/components/table#using-items-provider-functions
        let url = `${this.$hostname}/api/customers/getIsraelCustomers`;
        let data = await getItemsAsync(url);
        data.forEach((item) => (item.ignore = false));
        this.items = data;
      }
      this.isBusy = false;
      return this.items;
    },
    async showIgnoredDoctors() {
      this.isIgnoredBusy = true;
      let url = `${this.$hostname}/api/customers/getIsraelIgnoredDoctors`;
      let data = await getItemsAsync(url);
      data.sort((a, b) => a.doctor_number - b.doctor_number);
      // let n = 0;
      // data.forEach((item) => (item.number = ++n));
      this.ignoredDoctors = data;

      this.isIgnoredBusy = false;
      this.$refs["modal-ignored-doctors-1"].show();
    },
    async continueFlow() {
      this.isBusy = true;
      this.loadText = "Downloading reports...";
      if (!this.debug) {
        let today = new Date();
        today.setMonth(today.getMonth() - 1);
        let month = today.toLocaleString("en-US", { month: "short" });
        this.loadText += ` Syncing ${month} ${today.getFullYear()}`;
      }
      let url = `${this.$hostname}/api/customers/updateIsraelCustomersAndOrders`;
      let requestData = {
        customerNumbers: this.items
          .filter((item) => item.sync)
          .map((item) => item.doctor_number),
        debug: this.debug,
        addAnalysisID: this.addAnalysisID,
        updateCTDent: this.updateCTDent,
        fromDate: getDateString(this.fromDate)
      };
      let responseData = await postDataAsync(url, requestData, "blob");
      if (responseData) {
        safeFileWithDate(responseData, "israel_reports", "zip");
      }
      this.items = [];
      this.getCustomers();
      // this.isBusy = false;
    },
    async ignoreDoctors() {
      this.isIgnoringBusy = true;
      let url = `${this.$hostname}/api/customers/ignoreDoctors`;
      let requestData = this.items
        .filter((item) => item.ignore)
        .map((item) => item.doctor_number);
      let response = await postAsync(url, requestData);
      if (response.status == 200) {
        this.items = [];
        this.getCustomers();
      }
      this.isIgnoringBusy = false;
    },
  },
  created() {
    let today = new Date();
    this.fromDate.setMonth(today.getMonth() - 1);
    this.fromDate.setDate(1);
    this.fromDate.setHours(0, 0, 0, 0);

    this.getCustomers();
  },
  mounted: function () {
    // this.fromDate = new Date();
    // this.fromDate.setDate(0); // 0 will result in the last day of the previous month
    // this.fromDate.setDate(1);
    //makeColumnsResizable();
  },
};

// function makeColumnsResizable() {
//   var thElm;
//   var startOffset;

//   Array.prototype.forEach.call(
//     document.querySelectorAll("table th"),
//     function (th) {
//       th.style.position = "relative";

//       var grip = document.createElement("div");
//       grip.innerHTML = "&nbsp;";
//       grip.style.top = 0;
//       grip.style.right = 0;
//       grip.style.bottom = 0;
//       grip.style.width = "5px";
//       grip.style.position = "absolute";
//       grip.style.cursor = "col-resize";
//       grip.addEventListener("mousedown", function (e) {
//         thElm = th;
//         startOffset = th.offsetWidth - e.pageX;
//       });

//       th.appendChild(grip);
//     }
//   );

//   document.addEventListener("mousemove", function (e) {
//     if (thElm) {
//       thElm.style.width = startOffset + e.pageX + "px";
//     }
//   });

//   document.addEventListener("mouseup", function () {
//     thElm = undefined;
//   });
// }
</script>

<style>
.lb-date {
  margin: 0;
  padding: 0 !important;
  padding-top: 4px !important;
}
</style>
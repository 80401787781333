<template>
  <b-container fluid>
    <b-row class="marginBottom15">
      <b-col cols="auto">
        <b-button variant="outline-primary" size="sm" @click="loadSKU"
          >Load Items From SKU.INI File</b-button
        >
      </b-col>
      <b-col cols="auto">
        <b-button v-b-modal.modal-add-sku variant="outline-primary" size="sm"
          >Add SKU</b-button
        >
      </b-col>

      <b-col cols="auto">
        <b-button variant="outline-primary" size="sm" @click="zeroBalance"
          >Zero Balance
          <b-spinner small v-if="isZeroBusy"></b-spinner> </b-button
      ></b-col>

      <b-col cols="auto">
        <b-button
          variant="outline-primary"
          size="sm"
          @click="showSubscriptionsChargeSum"
          >Show sum of future charges
          <b-spinner small v-if="isSubscrBusy"></b-spinner> </b-button
      ></b-col>
    </b-row>

    <b-table
      :items="items"
      :fields="fields"
      :busy.sync="isBusy"
      show-empty
      striped
      hover
      class="my-1 text-left fontSizeSm"
      bordered
      small
      fixed
      responsive
      sticky-header="860px"
      :no-border-collapse="noCollapse"
      size="sm"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(actions)="row">
        <b-button
          variant="outline-secondary"
          size="sm"
          @click="remove(row.item, row.index, $event.target)"
          class="mr-2"
        >
          Remove
        </b-button>
        <b-button
          variant="outline-secondary"
          size="sm"
          @click="loadName(row.item, row.index, $event.target)"
          class="mr-1 mt-sm-1 mt-md-0"
        >
          Load Name
        </b-button>
      </template>
    </b-table>

    <b-modal
      id="modal-add-sku"
      ref="modal-add-sku"
      title="Add SKU"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="sku-form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="SKU Number"
          label-for="sku-number-input"
          invalid-feedback="SKU Number must be a positive number"
          :state="skuNumberState"
        >
          <b-form-input
            id="sku-number-input"
            v-model="skuNumber"
            :state="skuNumberState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>

    <!-- <div class="bottomBtnDiv">
      <b-button variant="outline-primary" size="sm">Zero Balance</b-button>
    </div> -->
  </b-container>
</template>

<script>
import { getItemsAsync, getDataAsync, postAsync } from "../apiHelper";

export default {
  data() {
    return {
      items: [],
      fields: [
        {
          key: "number",
          label: "SKU Number",
          sortable: true,
          thClass: "w20Column",
        },
        {
          key: "name",
          label: "Contract Name",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thClass: "btnColumn",
          tdClass: "btnColumn",
        },
      ],
      isBusy: false,
      isZeroBusy: false,
      isSubscrBusy: false,
      noCollapse: true,
      skuNumber: null,
      skuNumberState: null,
      msgConfig: {
        buttonSize: "sm",
        footerClass: "p-2 border-top-0",
        centered: true,
      },
      errConfig: {
        size: "lg",
        buttonSize: "sm",
        okVariant: "danger",
        footerClass: "p-2 border-top-0",
        centered: true,
      },
      confirmConfig: {
        size: "sm",
        buttonSize: "sm",
        okTitle: "OK",
        cancelTitle: "Cancel",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
        centered: true,
      },
    };
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  methods: {
    async showSubscriptionsChargeSum() {
      this.isSubscrBusy = true;
      let url = `${this.$hostname}/api/resetbs/getSubscriptionsChargeSum`;
      var data = await getDataAsync(url);
      if (data !== undefined && data !== null && !Number.isNaN(Number.parseFloat(data))) {
        this.$bvModal.msgBoxOk(
          `Sum of all future charges = ${data}$`,
          this.msgConfig
        );
      } else {
        this.$bvModal.msgBoxOk(
          "Сouldn't get charges info. Server Error " + data,
          this.errConfig
        );
      }

      this.isSubscrBusy = false;
    },
    async zeroBalance() {
      if (this.items.length > 0) {
        this.isZeroBusy = true;
        let url = `${this.$hostname}/api/resetbs/zeroBalance`;
        let response = await postAsync(url, this.items);
        if (response.status == 200) {
          var data = response.data;
          console.log(data);

          this.$bvModal
            .msgBoxConfirm(data.text, this.confirmConfig)
            .then(async (value) => {
              if (value) {
                this.isZeroBusy = true;
                let url = `${this.$hostname}/api/resetbs/zeroSubscriptions`;
                let response = await postAsync(url, data.subscriptionIds);
                if (response.status == 200) {
                  // this.$bvModal.msgBoxOk(
                  //   "All subscriptions were updated successfully.",
                  //   this.msgConfig
                  // );
                  this.showSubscriptionsChargeSum();
                } else {
                  this.$bvModal.msgBoxOk(
                    "Сouldn't reset subscriptions. Server Error " +
                      response.data,
                    this.errConfig
                  );
                }
                this.isZeroBusy = false;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$bvModal.msgBoxOk(
            "Сouldn't get subscriptions info. Server Error " + response.data,
            this.errConfig
          );
        }
        this.isZeroBusy = false;
      }
    },
    async loadSKU() {
      this.isBusy = true;
      let url = `${this.$hostname}/api/resetbs/loadSkuFromIniFile`;
      this.items = await getItemsAsync(url);
      this.isBusy = false;
    },
    async loadName(item) {
      this.isBusy = true;
      let url = `${this.$hostname}/api/resetbs/getContractName?skuNumber=${item.number}`;
      item.name = await getDataAsync(url);
      this.isBusy = false;
    },
    remove(item) {
      this.items = this.items.filter((x) => x !== item);
    },

    checkFormValidity() {
      const valid =
        this.$refs["sku-form"].checkValidity() && this.skuNumber > 0;
      this.skuNumberState = valid;
      return valid;
    },
    resetModal() {
      this.skuNumber = null;
      this.skuNumberState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.items.push({ number: this.skuNumber });
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-add-sku");
      });
    },
  },
  created() {
    this.loadSKU();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <b-container fluid>
    <b-row>
      <b-col cols="auto" md="7" class="marginLeft5">
        <b-form-group
          label="Input Priority Report:"
          label-cols="auto"
          label-align-sm="left"
          label-size="sm"
        >
          <b-form-file
            v-model="file1"
            :state="Boolean(file1)"
            size="sm"
            @input="fileInput"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-button variant="outline-primary" size="sm" @click="updateBalance"
          >Update Balance</b-button
        >
      </b-col>
    </b-row>

    <b-table
      :items="items"
      :fields="fields"
      :busy.sync="isBusy"
      show-empty
      striped
      hover
      class="my-1 text-left fontSizeSm"
      bordered
      small
      fixed
      responsive
      sticky-header="860px"
      :no-border-collapse="noCollapse"
      size="sm"
    >
    </b-table>
    <template #table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>
  </b-container>
</template>

<script>
import { postDataAsync, postAsync, safeFileWithDate } from "../apiHelper";

export default {
  data() {
    return {
      file1: null,
      items: [],
      fields: [
        {
          key: "customerName",
          label: "Customer Name",
          sortable: true,
        },
        {
          key: "balance",
          label: "Balance",
          sortable: true,
          thClass: "w20Column",
        },
        {
          key: "cephxID",
          label: "Cephx ID",
          sortable: true,
          thClass: "w20Column",
        },
      ],
      isBusy: false,
      noCollapse: true,
      msgConfig: {
        size: "sm",
        buttonSize: "sm",
        footerClass: "p-2 border-top-0",
        centered: true,
      },
      errConfig: {
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        footerClass: "p-2 border-top-0",
        centered: true,
      },
      confirmConfig: {
        size: "sm",
        buttonSize: "sm",
        okTitle: "OK",
        cancelTitle: "Cancel",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
        centered: true,
      },
    };
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  methods: {
    async fileInput(file) {
      this.isBusy = true;
      // console.log(file);
      this.items = [];
      let url = `${this.$hostname}/api/updateBalance/processPriorityReport`;
      let formData = new FormData();
      formData.append("file", file);
      let responseData = await postDataAsync(url, formData);
      if (responseData) {
        this.items = responseData;
        if (!this.items || !this.items.length) {
          this.$bvModal.msgBoxOk(
            "There are no valid customers in this file.",
            this.msgConfig
          );
        } else {
          url = `${this.$hostname}/api/updateBalance/createCsvFileFromReportItems`;
          let response = await postAsync(url, this.items, "blob");
          if (response.status == 200 && response.data) {
            safeFileWithDate(response.data, "Cloud_Balance", "csv", true);
          }
        }
      }
      this.isBusy = false;
    },

    updateBalance() {
      if (!this.items || !this.items.length) {
        this.$bvModal.msgBoxOk(
          "No customers to update balance.",
          this.msgConfig
        );
      } else {
        let text = `Please approve to update balance for ${this.items.length} customer(s) from "${this.file1.name}"`;
        this.$bvModal
          .msgBoxConfirm(text, this.confirmConfig)
          .then(async (value) => {
            if (value) {
              this.isBusy = true;
              let url = `${this.$hostname}/api/updateBalance/updateBalancesForReportItems`;
              let response = await postAsync(url, this.items);
              if (response.status == 200) {
                this.$bvModal.msgBoxOk(
                  "All balances were updated successfully.",
                  this.msgConfig
                );
              } else {
                this.$bvModal.msgBoxOk("Сouldn't update balances. Server Error " + response.data, this.errConfig);
              }
              this.isBusy = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

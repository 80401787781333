<template>
  <div id="app">
    <!-- <b-navbar>
      <b-navbar-nav>
        <b-nav-item-dropdown text="Menu" >
          <b-dropdown-item href="#">Israel</b-dropdown-item>
          <b-dropdown-item href="#">ROW</b-dropdown-item>
          <b-dropdown-item href="#">Debug</b-dropdown-item>
          <b-dropdown-item href="#">Add Analysis-ID</b-dropdown-item>
          <b-dropdown-item href="#">Show Reporting</b-dropdown-item>
          <b-dropdown-item href="#">Close past month</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar> -->

    <!-- <b-card no-body>
      <b-tabs content-class="mt-3" pills card vertical>
    </b-card> -->
    <LoginForm v-if="!isTabsVisible" />
    <b-tabs
      content-class="mt-3"
      v-if="isTabsVisible"
      justified
      @input="onInput"
    >
      <b-tab title="Menu" ><HomeTab0 /></b-tab>
      <b-tab title="1. Israel" active><IsraelTab1 v-if="visitedTabs.includes(1)"/></b-tab>
      <b-tab title="2. ROW"><RowTab2 v-if="visitedTabs.includes(2)"/></b-tab>
      <b-tab title="3. BS Balance"><BsBalanceTab3 /></b-tab>
      <b-tab title="4. Close invoices"><CloseInvoicesTab4 /></b-tab>
      <b-tab title="5. Balance update"><BalanceUpdate5 /></b-tab>
      <b-tab title="6. Reset BS"><ResetBsTab6 v-if="visitedTabs.includes(6)"/></b-tab>
      <b-tab title="7. Reporting" :title-item-class="reportingTitleClass"
        ><ReportingTab7 v-if="isReportingVisible" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import HomeTab0 from "./components/tabs/HomeTab0.vue";
import IsraelTab1 from "./components/tabs/IsraelTab1.vue";
import RowTab2 from "./components/tabs/RowTab2.vue";
import BsBalanceTab3 from "./components/tabs/BsBalanceTab3.vue";
import CloseInvoicesTab4 from "./components/tabs/CloseInvoicesTab4.vue";
import BalanceUpdate5 from "./components/tabs/BalanceUpdate5.vue";
import ResetBsTab6 from "./components/tabs/ResetBsTab6.vue";
import ReportingTab7 from "./components/tabs/ReportingTab7.vue";
import LoginForm from "./components/LoginForm.vue";

export default {
  name: "App",
  data() {
    return {
      isTabsVisible: false,
      isReportingVisible: false,
      visitedTabs: [],
    };
  },
  components: {
    HomeTab0,
    IsraelTab1,
    RowTab2,
    BsBalanceTab3,
    CloseInvoicesTab4,
    BalanceUpdate5,
    ResetBsTab6,
    ReportingTab7,
    LoginForm,
  },
  computed: {
    reportingTitleClass: function () {
      return this.isReportingVisible ? "" : "d-none";
    },
  },
  methods: {
    onInput(value) {
      if (!this.visitedTabs.includes(value)) {
        this.visitedTabs.push(value);
      }
    },
  },
  mounted: function () {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 0px;
}

.syncTh {
  width: 7%;
  text-align: center;
  pointer-events: none;
}

/* .syncTh .custom-checkbox {
  display: inline;
  margin: 7px; 
} */

.syncTh .custom-control-label::after {
  /* top: 0.16rem; */
  left: -1.35rem;
  pointer-events: auto;
}

.syncTh .custom-control-label::before {
  /* top: 0.16rem; */
  left: -1.35rem;
  pointer-events: auto;
}

.syncTd {
  width: 5%;
  text-align: center;
  padding-left: 10px !important;
}

.numberColumn {
  width: 35px;
}

.oneBtnColumn {
  width: 40px;
}

.w10Column {
  width: 10%;
}
.w15Column {
  width: 15%;
}
.w20Column {
  width: 20%;
}

.btnColumn {
  width: 25%;
  text-align: center;
}

.autoColumn {
  width: auto;
}

.marginLeft5 {
  margin-left: 5px;
}

.marginTop25 {
  margin-top: 25px;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.fontSizeSm {
  font-size: 0.875rem;
}

.bottomBtnDiv {
  text-align: right;
  margin-right: 10px;
  margin-top: 10px;
}

.formInput {
  margin-top: -4px;
  margin-left: -15px;
}
</style>

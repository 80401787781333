<template>
  <b-container fluid>
    <!-- <b-row class="marginBottom15">
      <b-col cols="auto">
        <b-form-checkbox switch> Debug </b-form-checkbox>
      </b-col>
    </b-row> -->
    <!-- <b-row class="marginBottom15">
      <b-col cols="auto">
        <b-form-checkbox switch> Add Analysis-ID </b-form-checkbox>
      </b-col>
    </b-row> -->
    <b-row class="marginBottom15">
      <b-col cols="auto">
        <b-button variant="outline-primary" size="sm" @click="showReporting">
          Show Reporting
        </b-button>
      </b-col>
    </b-row>
    <b-row class="marginBottom15">
      <b-col cols="auto">
        <b-button variant="outline-primary" size="sm" @click="closePastMonth">
          Close Past Month
          <b-spinner small v-if="isClosingPastMonth"></b-spinner>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="marginBottom15"> </b-row>

    <b-row>
      <b-col cols="auto">
        <label class="marginLeft5">Unsync cases: </label>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto">
        <b-form-group label="from:" label-cols="auto" label-align-sm="left" label-size="sm">
          <b-form-datepicker placeholder="Choose a date" v-model="fromUnsync" size="sm" class="mb-2" locale="en"
            today-button close-button value-as-date></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-form-group label="to:" label-cols="auto" label-align-sm="left" label-size="sm">
          <b-form-datepicker placeholder="Choose a date" v-model="toUnsync" size="sm" class="mb-2" locale="en"
            today-button close-button value-as-date></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto">
        <b-button variant="outline-primary" size="sm" @click="unsyncCases">Unsync cases
          <b-spinner small v-if="isUnsyncCasesBusy"></b-spinner>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { getDataAsync, postAsync } from "../apiHelper";
import { getDateString } from "../codeHelper";

export default {
  data() {
    return {
      isClosingPastMonth: false,
      noCollapse: true,
      msgConfig: {
        size: "sm",
        buttonSize: "sm",
        footerClass: "p-2 border-top-0",
        centered: true,
      },
      errConfig: {
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        footerClass: "p-2 border-top-0",
        centered: true,
      },
      confirmConfig: {
        size: "sm",
        buttonSize: "sm",
        okTitle: "OK",
        cancelTitle: "Cancel",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
        centered: true,
      },
      fromUnsync: new Date(),
      toUnsync: new Date(),
      isUnsyncCasesBusy: false,
    };
  },
  methods: {
    showReporting() {
      window.vueApp.isReportingVisible = true; //!window.vueApp.isReportingVisible;
    },
    async closePastMonth() {
      this.isClosingPastMonth = true;
      let url = `${this.$hostname}/api/action/getPastMonthCasesCount`;
      let data = await getDataAsync(url);

      if (data && data > 0) {
        let text = `Please approve resetting ${data} cases for the past month.`;
        this.$bvModal
          .msgBoxConfirm(text, this.confirmConfig)
          .then(async (value) => {
            if (value) {
              this.isClosingPastMonth = true;
              let url = `${this.$hostname}/api/action/resetPastMonthCases`;
              let response = await postAsync(url);
              if (response.status == 200) {
                this.$bvModal.msgBoxOk(
                  `${response.data} cases have been reset.`,
                  this.msgConfig
                );
              } else {
                this.$bvModal.msgBoxOk(
                  "Сouldn't reset cases. Server Error " + response.data,
                  this.errConfig
                );
              }
              this.isClosingPastMonth = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$bvModal.msgBoxOk(
          "No cases to reset for the past month.",
          this.msgConfig
        );
      }
      this.isClosingPastMonth = false;
    },
    async unsyncCases() {
      if (this.toUnsync < this.fromUnsync) {
        this.$bvModal.msgBoxOk(
          "Incorrect date range",
          this.errConfig
        );
        return;
      }

      this.isUnsyncCasesBusy = true;
      let url = `${this.$hostname}/api/action/unsyncCases`;
      let from = getDateString(this.fromUnsync);
      let to = getDateString(this.toUnsync);
      url += `?fromDate=${from}&toDate=${to}`;

      let response = await postAsync(url);
      if (response.status == 200) {
        this.$bvModal.msgBoxOk(
          `${response.data} cases have been unsynced`,
          this.msgConfig
        );
      } else {
        this.$bvModal.msgBoxOk(
          "Сouldn't unsync cases. Server Error " + response.data,
          this.errConfig
        );
      }
      this.isUnsyncCasesBusy = false;
    },
  },
  created() {
    let today = new Date();
    this.fromUnsync.setMonth(today.getMonth() - 1);
    this.fromUnsync.setDate(1);
    this.fromUnsync.setHours(0, 0, 0, 0);

    this.toUnsync.setDate(0);
    this.toUnsync.setHours(0, 0, 0, 0);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

import axios from 'axios';
import fileDownload from "js-file-download";

async function getItemsAsync(url) {
    // https://github.com/axios/axios
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}

async function getDataAsync(url, responseType, contentType) {
    try {
        var config = {};
        if (responseType) {
            config.responseType = responseType;
        }
        if (contentType) {
            config.headers = { 'content-type': contentType };
        }
        const response = await axios.get(url, config);
        return response.data;
    } catch (error) {
        console.log(error);
        return error && error.response ? error.response.data : null;
    }
}

async function postAsync(url, data, responseType, contentType) {
    try {
        var config = {};
        if (responseType) {
            config.responseType = responseType;
        }
        if (contentType) {
            config.headers = { 'content-type': contentType };
        }
        const response = await axios.post(url, data, config);
        return response;
    } catch (error) {
        return error ? error.response : error;
    }
}

async function postDataAsync(url, data, responseType, contentType) {
    try {
        var config = {};
        if (responseType) {
            config.responseType = responseType;
        }
        if (contentType) {
            config.headers = { 'content-type': contentType };
        }
        const response = await axios.post(url, data, config);
        return response.data;
    } catch (error) {
        return null;
        // return error.response.data;
    }
}

function safeFileWithDate(data, fileName, extension, isFullDate) {
    let fullName = `${fileName}_${getFormattedDate(isFullDate)}.${extension}`;
    fileDownload(data, fullName);
}

function getFormattedDate(isFullDate) {
    var date = new Date();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (month.toString().length == 1) {
        month = "0" + month;
    }
    if (day.toString().length == 1) {
        day = "0" + day;
    }

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    if (hours.toString().length == 1) {
        hours = "0" + hours;
    }
    if (minutes.toString().length == 1) {
        minutes = "0" + minutes;
    }
    if (seconds.toString().length == 1) {
        seconds = "0" + seconds;
    }

    return isFullDate
        ? `${day}_${month}_${date.getFullYear()}.${hours}${minutes}${seconds}`
        : `${date.getFullYear()}_${month}_${day}`;
}

export { getItemsAsync, getDataAsync, postAsync, postDataAsync, safeFileWithDate, getFormattedDate };
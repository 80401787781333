function b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

function switchItems(items, columnName, currentPage, perPage, value) {
    if (items && columnName) {
        let start = (currentPage - 1) * perPage;
        let end = start + perPage;
        items.slice(start, end).forEach((item) => (item[columnName] = value));
    }
}

function getItemsValue(items, columnName, currentPage, perPage) {
    if (items && columnName) {
        let start = (currentPage - 1) * perPage;
        let end = Math.min(start + perPage, items.length);
        for (let i = start; i < end; i++) {
            if (!items[i][columnName]) {
                return false;
            }
        }
        return true;
    }
    return false;
}

function getSortedItems(items, sortBy, sortDesc) {
    let copiedItems = items.slice(0);
    if (sortBy) {
        copiedItems = copiedItems.sort((a, b) => {
            let x = a[sortBy];
            let y = b[sortBy]; 
            if (x.toPrecision && y.toPrecision) {
                return sortDesc ? y - x : x - y;
            } else {
                return sortDesc ? y.localeCompare(x) : x.localeCompare(y);
            }
        });
    }
    return copiedItems;
}

function getDateString(localDate) {
    let h_offset = new Date().getTimezoneOffset() / 60;
    let tempDate = new Date(localDate);
    tempDate.setTime(tempDate.getTime() - h_offset * 60 * 60 * 1000);
    return tempDate.toJSON();
}

export 
{ 
    b64EncodeUnicode, b64DecodeUnicode, switchItems, 
    getItemsValue, getSortedItems, getDateString 
};
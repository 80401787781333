<template>
  <b-container fluid>
    <b-row>
      <b-col cols="auto" md="6" class="marginLeft5">
        <b-form-group
          label="Invoice File:"
          label-cols="auto"
          label-align-sm="left"
          label-size="sm"
        >
          <b-form-file
            v-model="invoiceFile"
            :state="Boolean(invoiceFile)"
            size="sm"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto" md="6" class="marginLeft5">
        <b-form-group
          label="Charge File:"
          label-cols="auto"
          label-align-sm="left"
          label-size="sm"
        >
          <b-form-file
            v-model="chargeFile"
            :state="Boolean(chargeFile)"
            size="sm"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="outline-primary"
          size="sm"
          class="marginBottom15"
          @click="generateTransactionReports"
          >Generate
          <b-spinner small v-if="isBusy"></b-spinner>
          </b-button
        >
      </b-col>
    </b-row>
    <!-- <b-form-textarea
      readonly
      size="sm"
      placeholder="Actions Log"
      rows="16"
    ></b-form-textarea> -->
  </b-container>
</template>

<script>
import { postAsync, safeFileWithDate } from "../apiHelper";

export default {
  data() {
    return {
      invoiceFile: null,
      chargeFile: null,
      isBusy: false,
      msgConfig: {
        size: "sm",
        buttonSize: "sm",
        footerClass: "p-2 border-top-0",
        centered: true,
      },
      errConfig: {
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        footerClass: "p-2 border-top-0",
        centered: true,
      },
    };
  },
  methods: {
    async generateTransactionReports() {
      // console.log(this.invoiceFile);
      // console.log(this.chargeFile);

      if (!this.invoiceFile) {
        this.$bvModal.msgBoxOk("Please specify invoice file.", this.msgConfig);
      } else {
        this.isBusy = true;
        let url = `${this.$hostname}/api/closeInvoices/processInvoiceAndChargeFiles`;
        let formData = new FormData();
        formData.append("invoiceFile", this.invoiceFile);
        formData.append("chargeFile", this.chargeFile);

        let response = await postAsync(url, formData, "blob");
        if (response.status == 200) {
          console.log(response);
          safeFileWithDate(response.data, "invoices_reports", "zip");
        } else {
          this.$bvModal.msgBoxOk("Failed to get reports " + response.data, this.errConfig);
        }
        this.isBusy = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <b-container fluid>
    <b-row>
      <b-col cols="auto">
        <label class="marginLeft5">Doctors: </label>
      </b-col>
    </b-row>
    <b-row class="marginBottom15">
      <b-col cols="auto">
        <b-button
          variant="outline-primary"
          size="sm"
          class="mb-2"
          @click="getDoctorsReport"
          >Export Report
          <b-spinner small v-if="isDoctorsBusy"></b-spinner>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto">
        <label class="marginLeft5">Cases: </label>
      </b-col>
    </b-row>
    <b-row class="marginBottom15">
      <b-col cols="auto">
        <b-form-checkbox switch v-model="filterForDoctors">
          Filter for doctors:
        </b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-input
          size="sm"
          class="formInput"
          v-model="doctorsFilter"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="marginBottom15">
      <b-col cols="auto">
        <b-form-checkbox switch v-model="filterForDateRange">
          Filter for date range:
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto">
        <b-form-group
          label="from:"
          label-cols="auto"
          label-align-sm="left"
          label-size="sm"
        >
          <b-form-datepicker
            placeholder="Choose a date"
            v-model="from"
            size="sm"
            class="mb-2"
            locale="en"
            today-button
            close-button
            value-as-date
          ></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-form-group
          label="to:"
          label-cols="auto"
          label-align-sm="left"
          label-size="sm"
        >
          <b-form-datepicker
            placeholder="Choose a date"
            v-model="to"
            size="sm"
            class="mb-2"
            locale="en"
            today-button
            close-button
            value-as-date
          ></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto">
        <b-button variant="outline-primary" size="sm" @click="getCasesReport"
          >Export Report
          <b-spinner small v-if="isCasesBusy"></b-spinner>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { getDataAsync, safeFileWithDate } from "../apiHelper";

export default {
  data() {
    return {
      from: new Date(),
      to: new Date(),
      isDoctorsBusy: false,
      isCasesBusy: false,
      filterForDoctors: false,
      filterForDateRange: true,
      doctorsFilter: null,
    };
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  methods: {
    async getDoctorsReport() {
      this.isDoctorsBusy = true;
      let url = `${this.$hostname}/api/report/getDoctorsReport`;
      let responseData = await getDataAsync(url, "blob");
      if (responseData) {
        safeFileWithDate(responseData, "doctors", "xlsx");
      }
      this.isDoctorsBusy = false;
    },
    async getCasesReport() {
      this.isCasesBusy = true;
      let url = `${this.$hostname}/api/report/getCasesReport`;
      if (this.filterForDoctors || this.filterForDateRange) {
        let suffix = "";
        if (this.filterForDoctors && this.doctorsFilter) {
          suffix += `doctorsFilter=${this.doctorsFilter}`;
        }
        if (
          this.filterForDateRange &&
          this.from &&
          this.to &&
          this.from <= this.to
        ) {
          if (suffix.length > 0) suffix += "&";
          let h = new Date().getTimezoneOffset() / 60;
          this.from.setTime(this.from.getTime() - h * 60 * 60 * 1000);
          this.to.setTime(this.to.getTime() - h * 60 * 60 * 1000);
          suffix += `fromDate=${this.from.toJSON()}&toDate=${this.to.toJSON()}`;
        }
        if (suffix.length > 0) url += "?" + suffix;
      }

      let responseData = await getDataAsync(url, "blob");
      if (responseData) {
        safeFileWithDate(responseData, "cases", "xlsx");
      }
      this.isCasesBusy = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div {
  text-align: left;
}
</style>

<template>
  <b-container>
    <b-row class="vh-100" align-v="center">
      <b-col />
      <b-col cols="8" md="6">
        <b-overlay
          :show="showOverlay"
          rounded="sm"
          opacity="0.3"
          class="p-2"
          style="margin-top: -150px"
        >
          <b-form @submit="onSubmit">
            <b-form-group
              id="input-group-1"
              label="User name:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.name"
                placeholder="Enter name"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Password:"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                type="password"
                v-model="form.password"
                placeholder="Enter password"
                required
              ></b-form-input>
            </b-form-group>
            <b-button type="submit" variant="primary" class="w-100 marginTop25"
              >Submit</b-button
            >
          </b-form>
        </b-overlay>
        <!-- <b-toast id="example-toast" title="Login" variant="danger">
          Hello, world! This is a toast message.
        </b-toast> -->
      </b-col>
      <b-col />
    </b-row>
    <b-alert
      v-model="loginError"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000; text-align: center"
      variant="danger"
      dismissible
      fade
      :show="3"
    >
      {{ errorText }}
    </b-alert>
  </b-container>
</template>

<script>
import { postAsync } from "./apiHelper";
import { b64EncodeUnicode } from "./codeHelper";

export default {
  data() {
    return {
      form: {
        name: "",
        password: "",
      },
      showOverlay: false,
      loginError: false,
      errorText: "Wrong user name or password! Please try again.",
    };
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      this.showOverlay = true;

      let authToken = b64EncodeUnicode(`${this.form.name}+${this.form.password}`);
      let url = `${this.$hostname}/api/user/login`;
      let requestData = {
        authToken,
      };
      let response = await postAsync(url, requestData);
      let isError = !response || response.status != 200;

      if (!isError) {
        // localStorage.setItem('authToken', authToken);
        this.showOverlay = false;
        this.loginError = false;
        window.vueApp.isTabsVisible = true;
      } else {
        this.showOverlay = false;
        this.errorText = response && response.status ? "Wrong user name or password! Please try again." 
          : "Server is unavailable! Please try again later.";

        this.loginError = true;
        // this.$bvToast.show("example-toast");
        // this.$bvToast.toast(`This is toast number ${this.toastCount}`, {
        //   title: 'BootstrapVue Toast',
        //   autoHideDelay: 5000,
        //   appendToast: append
        // });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

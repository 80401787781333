<template>
  <b-container fluid>
    <b-row>
      <b-col cols="auto" class="my-1">
        <b-button variant="outline-primary" size="sm" @click="showIgnoredDoctors">
          Show Ignored Doctors
          <b-spinner small v-if="isIgnoredBusy"></b-spinner>
        </b-button>
      </b-col>

      <b-col cols="auto" class="my-1">
        <b-button variant="outline-primary" size="sm" @click="showBlueSnapErrors">
          Show BlueSnap Errors
          <b-spinner small v-if="isBsErrorsBusy"></b-spinner>
        </b-button>
      </b-col>

      <b-col cols="auto" class="my-1">
        <b-button variant="outline-primary" size="sm" @click="showMasterDoctors">
          Show Master-ID
          <b-spinner small v-if="isMasterLoadBusy"></b-spinner>
        </b-button>
      </b-col>

      <b-col cols="auto" class="my-2">
        <b-form-checkbox v-model="checkAll" switch @change="switchAll">
          Check All
        </b-form-checkbox>
      </b-col>

      <b-col cols="auto" class="my-2">
        <b-form-checkbox v-model="debug" switch> Debug </b-form-checkbox>
      </b-col>

      <b-col cols="auto" class="my-2">
        <b-form-checkbox v-model="addAnalysisID" switch>
          Add Analysis-ID
        </b-form-checkbox>
      </b-col>

      <b-col cols="auto" class="my-1">
        <b-form-group label="From:" label-cols="auto" label-class="lb-date">
          <b-form-datepicker placeholder="Choose a date" v-model="fromDate" size="sm" locale="en" today-button
            close-button value-as-date></b-form-datepicker>
        </b-form-group>
      </b-col>

      <b-col cols="auto" class="my-1">
        <b-button variant="outline-primary" size="sm" @click="continueFlow">Download
        </b-button>
      </b-col>

      <b-col cols="auto" class="my-1">
        <b-button variant="outline-primary" size="sm" @click="ignoreDoctors">Ignore Doctors
          <b-spinner small v-if="isIgnoringBusy"></b-spinner>
        </b-button>
      </b-col>
    </b-row>

    <b-table :items="items" :fields="fields" :busy.sync="isBusy" show-empty striped hover
      class="my-1 text-left fontSizeSm" bordered small fixed responsive sticky-header="800px"
      :no-border-collapse="noCollapse" size="sm">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>{{ loadText }}</strong>
        </div>
      </template>

      <!-- A custom formatted header cell for field 'sync' -->
      <template #head(sync)="data">
        <span>{{ data.label }}</span>
        <b-form-checkbox @change="checkSyncAll" v-model="syncAll" />
      </template>
      <!-- A custom formatted row cell for field 'sync' -->
      <template v-slot:cell(sync)="row">
        <b-form-checkbox @change="checkSync" v-model="row.item.sync" />
      </template>

      <!-- A custom formatted header cell for field 'ignore' -->
      <template #head(ignore)="data">
        <span>{{ data.label }}</span>
        <b-form-checkbox @change="checkIgnoreAll" v-model="ignoreAll" />
      </template>
      <!-- A custom formatted row cell for field 'ignore' -->
      <template v-slot:cell(ignore)="row">
        <b-form-checkbox @change="checkIgnore" v-model="row.item.ignore" />
      </template>

      <template #cell()="data">
        <div class="text-truncate">{{ data.value }}</div>
      </template>
    </b-table>

    <b-modal id="modal-ignored-doctors-2" ref="modal-ignored-doctors-2" title="Ignored Doctors With Orders"
      ok-title="Continue" size="xl" centered scrollable ok-only>
      <b-table :items="ignoredDoctors" :fields="ignoredDoctorFields" show-empty striped hover
        class="my-1 text-left fontSizeSm" bordered small fixed responsive sticky-header="500px"
        :no-border-collapse="noCollapse" size="sm">
        <template #cell()="data">
          <div class="text-truncate">{{ data.value }}</div>
        </template>
      </b-table>
    </b-modal>

    <b-modal id="modal-bs-errors" ref="modal-bs-errors" title="BlueSnap Errors" ok-title="OK" @hidden="bsErrorsHidden"
      size="lg" centered scrollable ok-only>
      <b-table :items="bsErrorItems" :fields="bsErrorFields" show-empty striped hover class="my-1 text-left fontSizeSm"
        bordered small fixed responsive sticky-header="500px" :no-border-collapse="noCollapse" size="sm">
        <template #cell()="data">
          <div class="text-truncate">{{ data.value }}</div>
        </template>
      </b-table>
    </b-modal>

    <b-modal id="modal-master-doctors" ref="modal-master-doctors" title="Master-ID" size="lg" centered scrollable
      @ok="handleMasterIdOk" @cancel="handleMasterIdCancel">
      <b-table :items="masterDoctors" :fields="masterFields" show-empty striped hover class="my-1 text-left fontSizeSm"
        bordered small fixed responsive sticky-header="500px" :no-border-collapse="noCollapse" size="sm">

        <template #cell(number)="row">
          <div class="text-truncate">{{ row.index + 1 }}</div>
        </template>

        <template #cell()="data">
          <div class="text-truncate">{{ data.value }}</div>
        </template>

        <template #cell(combine)="row">
          <b-form-checkbox v-model="row.item.combine" />
        </template>

        <template #cell(actions)="row">
          <b-button size="sm" style="width: 30px;" @click="row.toggleDetails">
            {{ row.detailsShowing ? '-' : '+' }}
          </b-button>
        </template>

        <template #row-details="row">
          <br>
          <ul>
            <li v-for="item of row.item.sub_items" v-bind:key="item.sub_number">
              <b>ID:</b> {{ item.sub_number }} <b>Name:</b> {{ item.sub_name }}
            </li>
          </ul>
        </template>

      </b-table>

      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="ok()">
          Save
          <b-spinner small v-if="isMasterSaveBusy"></b-spinner>
        </b-button>
        <b-button @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import {
  getItemsAsync,
  postDataAsync,
  postAsync,
  safeFileWithDate,
} from "../apiHelper";
import {
  switchItems,
  getItemsValue,
  getSortedItems,
  getDateString
} from "../codeHelper";

const ciStampName = `combineInvoices`;

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, 25, { value: 1000, text: "Show a lot" }],
      isBusy: true,
      isBsErrorsBusy: false,
      isIgnoredBusy: false,
      isIgnoringBusy: false,
      isMasterLoadBusy: false,
      isMasterSaveBusy: false,
      checkAll: false,
      debug: true,
      addAnalysisID: false,
      combineInvoices: false,
      fromDate: new Date(),
      fields: [
        {
          key: "sync",
          sortable: false,
          thClass: "syncTh",
          tdClass: "syncTd",
        },
        {
          key: "ignore",
          sortable: false,
          thClass: "syncTh",
          tdClass: "syncTd",
        },
        {
          key: "full_name",
          label: "Customer",
          sortable: true,
        },
        {
          key: "doctor_number",
          label: "Customer Number",
          sortable: true,
        },
        {
          key: "orders_count",
          label: "# Orders",
          sortable: true,
          thClass: "w10Column",
        },
        {
          key: "email",
          label: "E-mail",
          sortable: true,
        },
        {
          key: "city",
          sortable: true,
        },
        {
          key: "state",
          sortable: true,
          thClass: "w10Column",
        },
      ],
      items: [],
      ignoredDoctors: [],
      ignoredDoctorFields: [
        {
          key: "doctor_number",
          label: "Customer Number",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "First Name",
          sortable: true,
        },
        {
          key: "last_name",
          label: "Last Name",
          sortable: true,
        },
        {
          key: "country",
          label: "Country",
          sortable: true,
        },
        {
          key: "orders_count",
          label: "# Orders",
          sortable: true,
        },
        {
          key: "first_order",
          label: "First Order in Month",
          sortable: true,
        },
        {
          key: "last_order",
          label: "Last Order in Month",
          sortable: true,
        },
      ],
      noCollapse: true,
      bsErrorItems: [],
      bsErrorFields: [
        {
          key: "cephxID",
          label: "Cephx ID",
          sortable: true,
        },
        {
          key: "customerName",
          label: "Customer Name",
          sortable: true,
        },
        {
          key: "paymentMethod",
          label: "Payment Method",
          sortable: true,
        },
        {
          key: "subscriberID",
          label: "Subscriber ID",
          sortable: true,
        },
      ],
      masterDoctors: [],
      masterFields: [
        {
          key: 'number',
          label: '#',
          thClass: "numberColumn"
        },
        {
          key: "master_number",
          label: "ID",
          sortable: true,
          thClass: "w10Column"
        },
        {
          key: "master_name",
          label: "Master Name",
          sortable: true,
        },
        {
          key: "count",
          label: "Count",
          sortable: true,
          thClass: "w10Column"
        },
        {
          key: "combine",
          label: "Combine",
          sortable: false,
          thClass: "w10Column",
          tdClass: "syncTd"
        },
        {
          key: 'actions',
          label: '+/-',
          thClass: "oneBtnColumn"
        }
      ],
      loadText: "Loading...",
      syncAll: false,
      ignoreAll: false,
      sortBy: null,
      sortDesc: false
    };
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  methods: {
    switchCombineInvoices() {
      localStorage.setItem(ciStampName, this.combineInvoices);
      console.log(ciStampName, this.combineInvoices);
    },
    sortChanged(e) {
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc;
      this.handlePageClick();
    },
    handlePageClick() {
      let sorted = getSortedItems(this.items, this.sortBy, this.sortDesc);
      this.syncAll = getItemsValue(sorted, 'sync', this.currentPage, sorted.length);
      this.ignoreAll = getItemsValue(sorted, 'ignore', this.currentPage, sorted.length);
    },
    checkSyncAll(value) {
      if (this.items) {
        let sorted = getSortedItems(this.items, this.sortBy, this.sortDesc);
        switchItems(sorted, 'sync', this.currentPage, sorted.length, value);
      }
    },
    checkIgnoreAll(value) {
      if (this.items) {
        let sorted = getSortedItems(this.items, this.sortBy, this.sortDesc);
        switchItems(sorted, 'ignore', this.currentPage, sorted.length, value);
      }
    },
    checkSync() {
      this.syncAll = getItemsValue(this.items, 'sync', this.currentPage, this.items.length);
    },
    checkIgnore() {
      this.ignoreAll = getItemsValue(this.items, 'ignore', this.currentPage, this.items.length);
    },
    switchAll(value) {
      if (this.items) {
        this.items.forEach((item) => (item.sync = value));
        this.syncAll = value;
        //this.checkSync();
      }
    },
    async getCustomers() {
      if (this.items.length == 0) {
        // https://bootstrap-vue.org/docs/components/table#using-items-provider-functions
        let url = `${this.$hostname}/api/customers/getRowCustomers`;

        let data = await getItemsAsync(url);
        data.forEach((item) => (item.ignore = false));
        this.items = data;
      }
      this.isBusy = false;
      return this.items;
    },
    async showIgnoredDoctors() {
      this.isIgnoredBusy = true;
      let url = `${this.$hostname}/api/customers/getRowIgnoredDoctors`;
      this.ignoredDoctors = await getItemsAsync(url);
      this.isIgnoredBusy = false;
      this.$refs["modal-ignored-doctors-2"].show();
    },
    async showBlueSnapErrors() {
      this.isBsErrorsBusy = true;
      let url = `${this.$hostname}/api/customers/getRowBsErrors`;
      this.bsErrorItems = await getItemsAsync(url);
      this.isBsErrorsBusy = false;
      this.$refs["modal-bs-errors"].show();
    },
    async showMasterDoctors() {
      this.isMasterLoadBusy = true;

      let url = `${this.$hostname}/api/customers/getRowMasterDoctors`;
      let data = await getItemsAsync(url);
      data.forEach((doctor) => doctor.combine = doctor.is_combined);
      this.masterDoctors = data;

      this.isMasterLoadBusy = false;
      this.$refs["modal-master-doctors"].show();
    },
    async handleMasterIdOk(bvModalEvent) {
      this.isMasterSaveBusy = true;
      bvModalEvent.preventDefault();
      console.log(this.masterDoctors[0].combine);

      let url = `${this.$hostname}/api/customers/updateRowCombinedDoctors`;
      let requestData = this.masterDoctors
        .reduce(function (data, doctor) {
          if (doctor.is_combined !== doctor.combine)
            data[doctor.master_number] = doctor.combine;
          return data;
        }, {});
      let response = await postAsync(url, requestData);
      console.log(response);

      this.isMasterSaveBusy = false;
      this.$refs["modal-master-doctors"].hide();
    },
    async handleMasterIdCancel() {
      this.masterDoctors.forEach(doctor => doctor.combine = doctor.is_combined);
    },
    async continueFlow() {
      this.isBusy = true;
      this.loadText = "Downloading reports...";
      if (!this.debug) {
        let today = new Date();
        today.setMonth(today.getMonth() - 1);
        let month = today.toLocaleString("en-US", { month: 'short' });
        this.loadText += ` Syncing ${month} ${today.getFullYear()}`;
      }
      let url = `${this.$hostname}/api/customers/updateRowCustomersAndOrders`;
      let requestData = {
        customerNumbers: this.items
          .filter((item) => item.sync)
          .map((item) => item.doctor_number),
        debug: this.debug,
        addAnalysisID: this.addAnalysisID,
        combineInvoices: this.combineInvoices,
        fromDate: getDateString(this.fromDate)
      };
      let responseData = await postDataAsync(url, requestData, "blob");
      if (responseData) {
        safeFileWithDate(responseData, "row_reports", "zip");
      }
      this.items = [];
      this.getCustomers();
      this.isBusy = false;
    },
    async ignoreDoctors() {
      this.isIgnoringBusy = true;
      let url = `${this.$hostname}/api/customers/ignoreDoctors`;
      let requestData = this.items
        .filter((item) => item.ignore)
        .map((item) => item.doctor_number);
      let response = await postAsync(url, requestData);
      if (response.status == 200) {
        this.items = [];
        this.getCustomers();
      }
      this.isIgnoringBusy = false;
    },

    async bsErrorsHidden() { },
  },
  created() {
    let today = new Date();
    this.fromDate.setMonth(today.getMonth() - 1);
    this.fromDate.setDate(1);
    this.fromDate.setHours(0, 0, 0, 0);

    let ciStamp = localStorage.getItem(ciStampName) === "true";
    console.log(ciStampName, ciStamp);
    this.combineInvoices = ciStamp;
    this.getCustomers();
  },
  mounted: function () {
    //makeColumnsResizable();
  },
};
</script>